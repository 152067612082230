import clsx from 'clsx';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Heading } from '../Heading';
import { Table } from '../Table';
import { TableBody } from '../TableBody';
import { AhpFootnotesTableRow } from '../AhpFootnotesTableRow';

import scss from './AhpFootnotes.module.scss';
import { BulletList } from '../BulletList';

const styles = scss as {
  root: string;
  heading: string;
  table: string;
};

interface Props {
  className?: string;
}

export const AhpFootnotes = ({ className }: Props) => {
  const { t } = useTranslation('footnotes');
  return (
    <div className={clsx(styles.root, className)}>
      <Heading className={styles.heading} level="h3">
        {t('ahpSwitchDetailsFaq.title')}
      </Heading>

      <Table className={styles.table}>
        <TableBody>
          <AhpFootnotesTableRow
            data={{
              Question: t('ahpSwitchDetailsFaq.question1'),
              Answer: (
                <Fragment>
                  <div>{t('ahpSwitchDetailsFaq.answer1.part1')}</div>
                  <div>{t('ahpSwitchDetailsFaq.answer1.part2')}</div>
                </Fragment>
              ),
            }}
          ></AhpFootnotesTableRow>
          <AhpFootnotesTableRow
            data={{
              Question: t('ahpSwitchDetailsFaq.question2'),
              Answer: (
                <Fragment>
                  <div>{t('ahpSwitchDetailsFaq.answer2.part1')}</div>
                  <div>{t('ahpSwitchDetailsFaq.answer2.part2')}</div>
                  <div>{t('ahpSwitchDetailsFaq.answer2.part3')}</div>
                  <div>{t('ahpSwitchDetailsFaq.answer2.part4')}</div>
                  <div>{t('ahpSwitchDetailsFaq.answer2.part5')}</div>
                </Fragment>
              ),
            }}
          ></AhpFootnotesTableRow>
          <AhpFootnotesTableRow
            data={{
              Question: t('ahpSwitchDetailsFaq.question3'),
              Answer: (
                <Fragment>
                  <div>{t('ahpSwitchDetailsFaq.answer3.part1')}</div>
                </Fragment>
              ),
            }}
          ></AhpFootnotesTableRow>
          <AhpFootnotesTableRow
            data={{
              Question: t('ahpSwitchDetailsFaq.question4'),
              Answer: (
                <Fragment>
                  <div>{t('ahpSwitchDetailsFaq.answer4.part1')}</div>
                  <div>{t('ahpSwitchDetailsFaq.answer4.part2')}</div>
                  <div>{t('ahpSwitchDetailsFaq.answer4.part3')}</div>
                </Fragment>
              ),
            }}
          ></AhpFootnotesTableRow>
          <AhpFootnotesTableRow
            data={{
              Question: t('ahpSwitchDetailsFaq.question5'),
              Answer: (
                <Fragment>
                  <div>
                    <span>
                      <a href={t('ahpSwitchDetailsFaq.answer5.part2')} target="_blank">
                        {t('ahpSwitchDetailsFaq.answer5.part1')}
                      </a>{' '}
                      {t('ahpSwitchDetailsFaq.answer5.part3')}
                    </span>
                  </div>
                  <div>{t('ahpSwitchDetailsFaq.answer5.part4')}</div>
                </Fragment>
              ),
            }}
          ></AhpFootnotesTableRow>
        </TableBody>
      </Table>
    </div>
  );
};
