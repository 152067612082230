import clsx from 'clsx';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import {
  createMultiLineSpan,
  getDollarAmount,
  hasApll,
  hasDpc,
  isMedishare,
  programLevelComparator,
  ProgramLevelPayload,
  SwitchOptionsPayload,
} from '../../util/helper';
import { Anchor } from '../Anchor';
import scss from './ProgramComparison.module.scss';

const styles = scss as {
  root: string;
  table: string;
  columnHeader: string;
  programNameColumn: string;
  monthlyShareColumn: string;
  coshareColumn: string;
  ahpColumn: string;
  optionsColumn: string;
  optionsColumnOrDivider: string;
  dash: string;
  asterisk: string;
  coshareParagraph: string;
  footnotes: string;
  footnotesEmphasis: string;
  footnotesSection: string;
};

export interface ProgramComparisonProps {
  /**
   * Class that overrides and augments CSS properties
   */
  className?: string;
  /**
   * Payload from the switch-options API
   */
  data: SwitchOptionsPayload;
}

/**
 * Component that displays the comparison table with all programs to choose from
 */
export const ProgramComparison = ({
  className,
  data,
}: ProgramComparisonProps) => {
  const { t, i18n } = useTranslation();

  const programLevels = data.Programs.map((program) => program.ProgramLevels)
    .reduce((previous, current) => {
      return previous.concat(...current);
    }, [] as ProgramLevelPayload[])
    .sort(programLevelComparator);

  const hasCoshare = !!programLevels.find((programLevel) =>
    programLevel.Name.startsWith('CS'),
  );

  const getProgramShortName = (programLevel: ProgramLevelPayload) => {
    if (isMedishare(programLevel)) {
      return t('medishareProgramShortName', {
        ahpAmount: programLevel.AhpAmount,
      });
    }
    return t('coshareProgramShortName', { ahpAmount: programLevel.AhpAmount });
  };

  return (
    <div className={clsx(styles.root, className)}>
      <div className={styles.table}>
        <div />
        <div className={styles.columnHeader}>{t('monthlyShareAmount')}</div>
        <div className={styles.columnHeader}>{t('annualHouseholdPortion')}</div>
        <div className={styles.columnHeader}>{t('coshareResponsibility')}</div>
        <div className={styles.columnHeader}>{t('programOptions')}</div>
        {programLevels.map((programLevel) => {
          return (
            <Fragment key={programLevel.Name}>
              <div className={styles.programNameColumn}>
                {getProgramShortName(programLevel)}
              </div>
              <div className={styles.monthlyShareColumn}>
                {getDollarAmount(
                  programLevel.TotalAmount,
                  false,
                  i18n.language,
                )}
              </div>
              <div className={styles.ahpColumn}>
                {getDollarAmount(programLevel.AhpAmount, false, i18n.language)}
              </div>
              <div className={styles.coshareColumn}>
                {programLevel.McrAmount ? (
                  <div className={styles.asterisk}>
                    {getDollarAmount(
                      programLevel.McrAmount - programLevel.AhpAmount,
                      false,
                      i18n.language,
                    )}
                  </div>
                ) : (
                  <div className={styles.dash} />
                )}
              </div>
              <div className={styles.optionsColumn}>
                {hasApll(programLevel) && (
                  <div>{createMultiLineSpan(t('apll'))}</div>
                )}
                {hasDpc(programLevel) && (
                  <Fragment>
                    <div className={styles.optionsColumnOrDivider}>
                      {t('orDivider')}
                    </div>
                    <div>{createMultiLineSpan(t('dpc'))}</div>
                  </Fragment>
                )}
                {programLevel.Options.length === 0 && (
                  <div className={styles.dash} />
                )}
              </div>
            </Fragment>
          );
        })}
      </div>
      <div className={styles.footnotes}>
        <div className={styles.footnotesSection}>
          <p>
            {t('guidelines.part1', { ns: 'footnotes' })}
            <Anchor
              className={styles.footnotesEmphasis}
              href="https://www.medishare.com/medi-share/what-is-medishare/how-medi-share-works/medi-share-guidelines"
              target="_blank"
            >
              {t('guidelines.part2', { ns: 'footnotes' })}
            </Anchor>
            {t('guidelines.part3', { ns: 'footnotes' })}
          </p>
        </div>
        {hasCoshare && (
          <div className={styles.footnotesSection}>
            <p className={styles.coshareParagraph}>
              <span className={styles.footnotesEmphasis}>
                {t('coshare.part1', { ns: 'footnotes' })}
              </span>
              {t('coshare.part2', { ns: 'footnotes' })}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};
