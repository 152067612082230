import MuiTableHead, {
  TableHeadProps as MuiTableHeadProps,
} from '@material-ui/core/TableHead';
import React, { FunctionComponent } from 'react';
import { Classes } from '../../../util/storybook';
import scss from './TableHead.module.scss';

const classes = scss as Classes<MuiTableHeadProps>;

export type TableHeadProps = MuiTableHeadProps;

export const TableHead: FunctionComponent<TableHeadProps> = (props) => {
  return <MuiTableHead classes={{ root: classes.root }} {...props} />;
};
