import clsx from 'clsx';
import React from 'react';
import scss from './Anchor.module.scss';

const styles = scss as {
  root: string;
};

type Props = React.DetailedHTMLProps<
  React.AnchorHTMLAttributes<HTMLAnchorElement>,
  HTMLAnchorElement
>;

export const Anchor = ({ className, children, ...props }: Props) => {
  return (
    <a className={clsx(styles.root, className)} {...props}>
      {children}
    </a>
  );
};
