import { i18n } from 'i18next';
import React, { ReactNode, useEffect, useState } from 'react';
import { I18nextProvider } from 'react-i18next';
import { createI18n, Language } from '../../i18n';

interface Props {
  language: Language;
  children?: ReactNode;
}

export const I18nProvider = ({ language, children }: Props) => {
  const [i18n, setI18n] = useState(undefined as i18n | undefined);
  const initializeI18n = async () => {
    setI18n(await createI18n());
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    initializeI18n();
  }, []);

  useEffect(() => {
    if (i18n) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      i18n.changeLanguage(language);
    }
  }, [language, i18n]);

  return i18n ? (
    <I18nextProvider i18n={i18n}>{children}</I18nextProvider>
  ) : null;
};
