import clsx from 'clsx';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import scss from './CoshareModalContent.module.scss';

const styles = scss as {
  root: string;
  emphasis: string;
  smallNote: string;
  checkbox: string;
  checkboxLabel: string;
};

type CoshareAcknowledgeHandler = (acknowledged: boolean) => void;

interface Props {
  className?: string;
  acknowledged: boolean;
  onAcknowledge: CoshareAcknowledgeHandler;
}

export const CoshareModalContent = ({
  className,
  acknowledged,
  onAcknowledge,
}: Props) => {
  const { t } = useTranslation('footnotes');

  return (
    <div className={clsx(styles.root, className)}>
      <p>{t('modals.coshareAcknowledgement.part1')}</p>
      <p>{t('modals.coshareAcknowledgement.part2')}</p>

      <div className={styles.checkbox}>
        <input
          checked={acknowledged}
          id="coshareAcknowledgement"
          name="programOption"
          onChange={(event) => onAcknowledge(event.target.checked)}
          type="checkbox"
        />
        <label
          className={styles.checkboxLabel}
          htmlFor="coshareAcknowledgement"
        >
          {t('modals.coshareAcknowledgement.part3', { ns: 'footnotes' })}
        </label>
      </div>
    </div>
  );
};
