import MuiTableBody, {
  TableBodyProps as MuiTableBodyProps,
} from '@material-ui/core/TableBody';
import React, { FunctionComponent } from 'react';
import { Classes } from '../../../util/storybook';
import scss from './TableBody.module.scss';

const classes = scss as Classes<MuiTableBodyProps>;

export type TableBodyProps = MuiTableBodyProps;

export const TableBody: FunctionComponent<TableBodyProps> = (props) => {
  return <MuiTableBody classes={{ root: classes.root }} {...props} />;
};
