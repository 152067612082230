import { Collapse, IconButton } from '@material-ui/core';
import { KeyboardArrowRight, KeyboardArrowUp } from '@material-ui/icons';
import React, { Fragment, FunctionComponent } from 'react';
import { useToggle } from 'react-use';
import { currencyFormatter, dateFormatter } from '../../../util/formatter';
import { ApiBill } from '../../../util/types';
import { TableCell, TableRow } from '../../atoms';
import { MedicalSpendHistoryDetails } from '../MedicalSpendHistoryDetails';
import scss from './MedicalSpendHistoryRow.module.scss';

const classes = scss as {
  detailsRoot: string;
  details: string;
  shevronColumn: string;
};

export type MedicalSpendHistoryRowProps = {
  /**
   * Medical spend history total numbers
   */
  data: ApiBill;
  /**
   * Class applied to both the main row and the collapsed content row
   */
  className?: string;
  /**
   * Callback to call when user presses `Download Explanation of Sharing` button
   */
  onDownloadEOS: (data: ApiBill) => void;
};

export const MedicalSpendHistoryRow: FunctionComponent<MedicalSpendHistoryRowProps> = ({
  data,
  className,
  onDownloadEOS,
}) => {
  const [collapsed, toggleCollapsed] = useToggle(true);
  return (
    <Fragment>
      <TableRow
        className={className}
        hideDivider
        selected={!collapsed}
        size="large"
      >
        <TableCell className={classes.shevronColumn}>
          <IconButton
            aria-label="expand row"
            onClick={() => {
              toggleCollapsed();
            }}
            size="small"
          >
            {collapsed ? <KeyboardArrowRight /> : <KeyboardArrowUp />}
          </IconButton>
        </TableCell>
        <TableCell contentSize="small">
          {dateFormatter.format(Date.parse(data.dateIncurred))}
        </TableCell>
        <TableCell contentSize="small">
          {dateFormatter.format(Date.parse(data.dateReceived))}
        </TableCell>
        <TableCell contentSize="small">
          {data.processed && data.datePaid
            ? dateFormatter.format(Date.parse(data.datePaid))
            : 'Pending'}
        </TableCell>
        <TableCell contentSize="small">{`${data.patientFirstName} ${data.patientLastName}`}</TableCell>
        <TableCell contentSize="small">{data.providerName}</TableCell>
        <TableCell contentSize="small">
          {data.processed &&
            currencyFormatter.format(data.overallMemberResponsibility)}
        </TableCell>
      </TableRow>
      <TableRow className={classes.detailsRoot}>
        <TableCell className={classes.details} colSpan={7}>
          <Collapse in={!collapsed} timeout="auto" unmountOnExit>
            <MedicalSpendHistoryDetails
              data={data}
              onDownloadEOS={onDownloadEOS}
            />
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  );
};
