/* eslint-disable react/jsx-no-literals */
import React, { FunctionComponent } from 'react';
import { currencyFormatter, dateFormatter } from '../../../util/formatter';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '../../atoms';
import scss from './MedicalSpendHistoryTotals.module.scss';

const classes = scss as {
  root: string;
  title: string;
  table: string;
};

export type MedicalSpendHistoryTotalsData = {
  chargeAmounts: number;
  discounts: number;
  providerFees: number;
  ineligible: number;
  sharedAmounts: number;
  myResponsibility: number;
  appliedToAhp: number;
  startDate: string;
  endDate: string;
};

export type MedicalSpendHistoryTotalsProps = {
  /**
   * Don't allow children elements
   */
  children?: never;
  /**
   * Medical spend history total numbers
   */
  data: MedicalSpendHistoryTotalsData;
  /**
   * Class name for the root element
   */
  className?: string;
};

export const MedicalSpendHistoryTotals: FunctionComponent<MedicalSpendHistoryTotalsProps> = ({
  data,
}) => {
  return (
    <div className={classes.root}>
      <div className={classes.title}>
        <Typography className={classes.title} gutterBottom variant="h3">
          TOTALS:
        </Typography>
        <Typography variant="body2">
          {`For membership year ${dateFormatter.format(
            Date.parse(data.startDate),
          )} to ${dateFormatter.format(Date.parse(data.endDate))}`}
        </Typography>
      </div>
      <TableContainer>
        <Table className={classes.table}>
          <TableHead>
            <TableRow size="large">
              <TableCell>Charge Amounts</TableCell>
              <TableCell>Discounts</TableCell>
              <TableCell>Provider Fees</TableCell>
              <TableCell>Ineligible</TableCell>
              <TableCell>Shared Amounts</TableCell>
              <TableCell>My Responsibility</TableCell>
              <TableCell>Applied to AHP</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow size="large">
              <TableCell bold contentSize="medium">
                {currencyFormatter.format(data.chargeAmounts)}
              </TableCell>
              <TableCell bold contentSize="medium">
                {currencyFormatter.format(data.discounts)}
              </TableCell>
              <TableCell bold contentSize="medium">
                {currencyFormatter.format(data.providerFees)}
              </TableCell>
              <TableCell bold contentSize="medium">
                {currencyFormatter.format(data.ineligible)}
              </TableCell>
              <TableCell bold contentSize="medium">
                {currencyFormatter.format(data.sharedAmounts)}
              </TableCell>
              <TableCell bold contentSize="medium">
                {currencyFormatter.format(data.myResponsibility)}
              </TableCell>
              <TableCell bold contentSize="medium">
                {currencyFormatter.format(data.appliedToAhp)}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
