import IconButton, {
  IconButtonProps as MuiIconButtonProps,
} from '@material-ui/core/IconButton';

import Collapse, {
  CollapseProps as MuiCollapseProps,
} from '@material-ui/core/Collapse';

import { TableRow } from '../TableRow';
import { TableCell } from '../TableCell';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import clsx from 'clsx';
import React, { Fragment, FunctionComponent, useState } from 'react';

import { ClassesProp } from '../../util/types';
import scss from './AhpFootnotesTableRow.module.scss';
import { FootnotesSwitchDetails } from '../../util/helper';
import { BulletList } from '../BulletList';

const classes = scss as {
  root: string;
  detailsRoot: string;
  details: string;
};

export type AhpFootnotesTableRowProps = {
  /**
   * Is this the principal call to action on the page?
   */
  className?: string;
  data: FootnotesSwitchDetails;
};

/**
 * Primary UI component for user interaction
 */
export const AhpFootnotesTableRow: FunctionComponent<AhpFootnotesTableRowProps> = ({
  className,
  data,
}) => {
  const [collapsed, toggleCollapsed] = useState(false);

  return (
    <Fragment>
      <TableRow
        className={clsx(classes.root, className)}
        hideDivider
        size="small"
      >
        <TableCell contentSize="small" size="small" style={{ width: 25 }}>
          <IconButton
            aria-label="expand row"
            onClick={() => {
              toggleCollapsed(!collapsed);
            }}
            size="small"
          >
            {collapsed ? <KeyboardArrowDownIcon /> : <KeyboardArrowRight />}
          </IconButton>
        </TableCell>
        <TableCell>{data.Question}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell />
        <TableCell>
          <Collapse in={collapsed} timeout="auto" unmountOnExit>
            <BulletList>{data.Answer}</BulletList>
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  );
};
