import clsx from 'clsx';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { getDollarAmount, ProgramLevelPayload } from '../../util/helper';
import { Anchor } from '../Anchor';
import { BulletList } from '../BulletList';
import { Heading } from '../Heading';
import scss from './ProgramModalContent.module.scss';

const styles = scss as {
  root: string;
  emphasis: string;
  summary: string;
  summaryHeading: string;
  whatsIncluded: string;
  summaryList: string;
  table: string;
  moreInfo: string;
};

interface Props {
  className?: string;
  data: ProgramLevelPayload;
}

export const ProgramModalContent = ({ className, data }: Props) => {
  const { t, i18n } = useTranslation('footnotes');

  return (
    <div className={clsx(styles.root, className)}>
      <div className={styles.summary}>
        {data.McrAmount && (
          <Fragment>
            <Heading level="h3">{t('coshareSummary.summary')}</Heading>
            <BulletList>
              <div>
                <em className={styles.emphasis}>
                  {t('coshareSummary.ahp', {
                    ahpAmount: getDollarAmount(
                      data.AhpAmount,
                      false,
                      i18n.language,
                    ),
                  })}
                </em>
              </div>
              <div>
                <span>
                  <em className={styles.emphasis}>
                    {t('coshareSummary.coshare.part1')}
                  </em>
                  {t('coshareSummary.coshare.part2', {
                    coshareAmount: getDollarAmount(
                      data.McrAmount - data.AhpAmount,
                      false,
                      i18n.language,
                    ),
                  })}
                </span>
              </div>
              <div>
                <span>
                  <em className={styles.emphasis}>
                    {t('coshareSummary.expense.part1')}
                  </em>
                  {t('coshareSummary.expense.part2', {
                    mcrAmount: getDollarAmount(
                      data.McrAmount,
                      false,
                      i18n.language,
                    ),
                  })}
                </span>
              </div>
            </BulletList>
          </Fragment>
        )}
        <Heading className={clsx(styles.whatsIncluded)} level="h3">
          {t('whatsIncluded')}
        </Heading>
      </div>
      <div className={styles.table}>
        <div>
          <BulletList>
            <div>{t('modals.program.part1')}</div>
            <div>{t('modals.program.part2')}</div>
            <div>{t('modals.program.part3')}</div>
            <div>{t('modals.program.part4')}</div>
            <div>{t('modals.program.part5')}</div>
            <div>
              <span>
                {t('modals.program.part6')}
                <Anchor
                  className={styles.emphasis}
                  href="https://mychristiancare.org/maternity"
                  target="_blank"
                >
                  {t('modals.program.part7')}
                </Anchor>
              </span>
            </div>
          </BulletList>
        </div>
        <div>
          <BulletList>
            <div>{t('modals.program.part8')}</div>
            <div>{t('modals.program.part9')}</div>
            <div>{t('modals.program.part10')}</div>
            <div>{t('modals.program.part11')}</div>
            <div>{t('modals.program.part12')}</div>
            <div>{t('modals.program.part13')}</div>
          </BulletList>
        </div>
      </div>

      <p className={styles.moreInfo}>
        {t('modals.programMoreInfo.part1')}
        <Anchor
          className={styles.emphasis}
          href="https://www.medishare.com/medi-share/what-is-medishare/how-medi-share-works/medi-share-guidelines"
          target="_blank"
        >
          {t('modals.programMoreInfo.part2')}
        </Anchor>
        {t('modals.programMoreInfo.part3')}
      </p>
    </div>
  );
};
