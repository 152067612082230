import MuiTypography, {
  TypographyProps as MuiTypographyProps,
} from '@material-ui/core/Typography';
import clsx from 'clsx';
import React, { ElementType, FunctionComponent } from 'react';
import { Classes } from '../../../util/storybook';
import scss from './Typography.module.scss';

const classes = scss as Classes<MuiTypographyProps> & {
  dark: string;
};

export type TypographyProps = MuiTypographyProps & {
  /**
   * Apply colors for a dark background
   */
  dark?: boolean;
  /**
   * Use a component different from the value of `variant`
   */
  component?: ElementType;
};

export const Typography: FunctionComponent<TypographyProps> = ({
  dark = false,
  ...props
}) => {
  return (
    <MuiTypography
      classes={{
        h1: classes.h1,
        h2: classes.h2,
        h3: classes.h3,
        h4: classes.h4,
        h5: classes.h5,
        h6: classes.h6,
        body1: classes.body1,
        body2: classes.body2,
        button: classes.button,
      }}
      className={clsx(dark && classes.dark)}
      {...props}
    />
  );
};
