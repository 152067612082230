/* eslint-disable jsx-a11y/control-has-associated-label */
import clsx from 'clsx';
import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import ReactModal, { Props as ReactModalProps } from 'react-modal';
import { SetRequired } from 'type-fest';
import { Button } from '../Button';
import { Heading } from '../Heading';

import scss from './Modal.module.scss';

const styles = scss as {
  base: string;
  afterOpen: string;
  beforeClose: string;
  overlayBase: string;
  overlayAfterOpen: string;
  overlayBeforeClose: string;
  bodyOpen: string;
  heading: string;
  title: string;
  headingCloseButton: string;
  content: string;
  footing: string;
  footingButton: string;
  footingCloseButton: string;
  portal: string;
};

type ClickSelectHandler = () => void;

interface Props
  extends SetRequired<
    Omit<
      ReactModalProps,
      | 'closeTimeoutMS'
      | 'shouldCloseOnEsc'
      | 'shouldCloseOnOverlayClick'
      | 'className'
    >,
    'onRequestClose'
  > {
  children: ReactNode | ReactNode[];
  rootElementSelector: string;
  onClickSelect?: ClickSelectHandler;
  isSelectDisabled?: boolean;
}

export const Modal = ({
  onRequestClose,
  children,
  contentLabel,
  rootElementSelector,
  onClickSelect,
  isSelectDisabled,
  ...props
}: Props) => {
  const { t } = useTranslation();
  ReactModal.setAppElement(rootElementSelector);

  return (
    <ReactModal
      bodyOpenClassName={styles.bodyOpen}
      className={{
        base: styles.base,
        afterOpen: styles.afterOpen,
        beforeClose: styles.beforeClose,
      }}
      closeTimeoutMS={100}
      contentLabel={contentLabel}
      onRequestClose={onRequestClose}
      overlayClassName={{
        base: styles.overlayBase,
        afterOpen: styles.overlayAfterOpen,
        beforeClose: styles.overlayBeforeClose,
      }}
      portalClassName={styles.portal}
      shouldCloseOnEsc
      shouldCloseOnOverlayClick
      {...props}
    >
      <div className={styles.heading}>
        <Heading className={styles.title} level="h2">
          {contentLabel}
        </Heading>
        <button
          className={styles.headingCloseButton}
          onClick={onRequestClose}
          type="button"
        />
      </div>

      <div className={styles.content}>{children}</div>

      <div className={styles.footing}>
        <Button
          className={clsx(styles.footingButton, styles.footingCloseButton)}
          isLight
          onClick={onRequestClose}
        >
          {t('close')}
        </Button>

        {onClickSelect && (
          <Button
            className={styles.footingButton}
            disabled={isSelectDisabled}
            onClick={() => onClickSelect()}
          >
            {t('select')}
          </Button>
        )}
      </div>
    </ReactModal>
  );
};
