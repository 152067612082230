import MuiTableCell, {
  TableCellProps as MuiTableCellProps,
} from '@material-ui/core/TableCell';
import clsx from 'clsx';
import React, { FunctionComponent } from 'react';
import { UnreachableCaseError } from 'ts-essentials';
import { Classes } from '../../../util/storybook';
import scss from './TableCell.module.scss';

const classes = scss as Classes<MuiTableCellProps> & {
  contentSizeSmall: string;
  contentSizeMedium: string;
  contentSizeLarge: string;
  bold: string;
  border: string;
  dark: string;
};

type ContentSize = 'small' | 'medium' | 'large';

export type TableCellProps = MuiTableCellProps & {
  bold?: boolean;
  border?: boolean;
  contentSize?: ContentSize;
  dark?: boolean;
};

const getContentSizeClass = (size?: ContentSize): string | undefined => {
  if (!size) {
    return undefined;
  }
  switch (size) {
    case 'small':
      return classes.contentSizeSmall;
    case 'medium':
      return classes.contentSizeMedium;
    case 'large':
      return classes.contentSizeLarge;
    default:
      throw new UnreachableCaseError(size);
  }
};

export const TableCell: FunctionComponent<TableCellProps> = ({
  className,
  bold = false,
  border = false,
  dark = false,
  contentSize,
  ...props
}) => {
  return (
    <MuiTableCell
      classes={{
        root: classes.root,
        head: classes.head,
        body: classes.body,
        footer: classes.footer,
      }}
      className={clsx(
        getContentSizeClass(contentSize),
        bold && classes.bold,
        border && classes.border,
        dark && classes.dark,
        className,
      )}
      {...props}
    />
  );
};
