import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import clsx from 'clsx';
import React, { FunctionComponent } from 'react';
import { dateFormatter } from '../../../util/formatter';
import scss from './MedicalSpendHistoryHeading.module.scss';

const classes = scss as {
  root: string;
  bills: string;
};

export type MedicalSpendHistoryHeadingProps = {
  /**
   * Don't allow children elements
   */
  children?: never;
  /**
   * Class name for the root element
   */
  className?: string;
};

export const MedicalSpendHistoryHeading: FunctionComponent<MedicalSpendHistoryHeadingProps> = ({
  className,
}) => {
  return (
    <div style={{ backgroundColor: '#edeeed', padding: '0.5rem' }}>
      <Table className={clsx(classes.root, className)} size="small">
        <TableHead>
          <TableRow>
            <TableCell
              align="center"
              style={{
                border: 'unset',
                fontFamily: 'Gotham',
                fontWeight: 300,
                textTransform: 'uppercase',
                color: '#333',
              }}
              width="25%"
            >
              Start Date
            </TableCell>
            <TableCell
              align="center"
              style={{
                border: 'unset',
                fontFamily: 'Gotham',
                fontWeight: 300,
                textTransform: 'uppercase',
                color: '#333',
              }}
              width="25%"
            >
              Reset Date
            </TableCell>
            <TableCell
              align="center"
              style={{
                border: 'unset',
                fontFamily: 'Gotham',
                fontWeight: 300,
                textTransform: 'uppercase',
              }}
              width="25%"
            >
              Program
            </TableCell>
            <TableCell
              align="center"
              style={{
                border: 'unset',
                fontFamily: 'Gotham',
                fontWeight: 300,
                textTransform: 'uppercase',
                color: '#333',
              }}
              width="25%"
            >
              AHP Level
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell
              align="center"
              style={{
                border: 'unset',
                fontFamily: 'Gotham',
                fontWeight: 500,
                color: '#333',
              }}
              width="25%"
            >
              {dateFormatter.format(Date.parse('2021-01-01'))}
            </TableCell>
            <TableCell
              align="center"
              style={{
                border: 'unset',
                fontFamily: 'Gotham',
                fontWeight: 500,
                color: '#333',
              }}
              width="25%"
            >
              {dateFormatter.format(Date.parse('2021-12-31'))}
            </TableCell>
            <TableCell
              align="center"
              style={{
                border: 'unset',
                fontFamily: 'Gotham',
                fontWeight: 500,
                color: '#333',
              }}
              width="25%"
            >
              Medi-Share
            </TableCell>
            <TableCell
              align="center"
              style={{
                border: 'unset',
                fontFamily: 'Gotham',
                fontWeight: 500,
                color: '#333',
              }}
              width="25%"
            >
              AHP 9000
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );
};
