import React from 'react';
import { Language } from '../../i18n';
import { SwitchOptionsPayload } from '../../util/helper';
import { I18nProvider } from '../I18nProvider/I18nProvider';
import { SwitchToolContent } from '../SwitchToolContent';
import {
  CancelSwitchRequestHandler,
  ConfirmSwitchRequestHandler,
} from '../SwitchToolContent/SwitchToolContent';
import scss from './SwitchTool.module.scss';

const styles = scss as {
  root: string;
  circularProgress: string;
};

export interface SwitchToolProps {
  /**
   * Class that overrides and augments CSS properties
   */
  className?: string;
  /**
   * i18n locale
   */
  language: Language;
  /**
   * Handler for when the user cancels the switch component by pressing the Back button
   */
  onCancelSwitchRequest: CancelSwitchRequestHandler;
  /**
   * Handler for when the user confirms program selection
   */
  onConfirmSwitchRequest: ConfirmSwitchRequestHandler;
  /**
   * The ID of the root element, used to display modals, e.g. #root
   */
  rootElementSelector: string;
  /**
   * Display the rate with the heathy discount
   */
  showHealthDiscount?: boolean;
  /**
   * Payload returned by the swtich-options API
   */
  data: SwitchOptionsPayload;
}

/**
 * Component that displays eligible programs and lets the user select a program to switch to
 */
export const SwitchTool = ({
  className,
  language,
  onCancelSwitchRequest,
  onConfirmSwitchRequest,
  rootElementSelector,
  showHealthDiscount = true,
  data,
}: SwitchToolProps) => {
  return (
    <I18nProvider language={language}>
      <div className={styles.root}>
        <SwitchToolContent
          className={className}
          data={data}
          onCancelSwitchRequest={onCancelSwitchRequest}
          onConfirmSwitchRequest={onConfirmSwitchRequest}
          rootElementSelector={rootElementSelector}
          showHealthDiscount={showHealthDiscount}
        />
      </div>
    </I18nProvider>
  );
};
