import MuiButton, {
  ButtonProps as MuiButtonProps,
} from '@material-ui/core/Button';
import React, { FunctionComponent } from 'react';
import { Classes } from '../../../util/storybook';
import scss from './Button.module.scss';

const classes = scss as Classes<MuiButtonProps> & {
  normal: string;
  special: string;
};

export type ButtonProps = MuiButtonProps & {
  /**
   * Is this the principal call to action on the page?
   */
  accented?: boolean;
};

/**
 * Primary UI component for user interaction
 */
export const Button: FunctionComponent<ButtonProps> = ({
  accented = false,
  size = 'medium',
  variant = 'contained',
  ...props
}) => {
  return (
    <MuiButton
      classes={{
        root: classes.root,
        contained: accented ? classes.special : classes.normal,
        label: classes.label,
      }}
      size={size}
      variant={variant}
      {...props}
    />
  );
};
