import MuiTableContainer, {
  TableContainerProps as MuiTableContainerProps,
} from '@material-ui/core/TableContainer';
import React, { FunctionComponent } from 'react';
import { Classes } from '../../../util/storybook';
import scss from './TableContainer.module.scss';

const classes = scss as Classes<MuiTableContainerProps>;

export type TableContainerProps = MuiTableContainerProps;

export const TableContainer: FunctionComponent<TableContainerProps> = (
  props,
) => {
  return <MuiTableContainer classes={{ root: classes.root }} {...props} />;
};
