import clsx from 'clsx';
import React from 'react';
import scss from './LinkButton.module.scss';

const styles = scss as {
  root: string;
};

type Props = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

export const LinkButton = ({ className, ...props }: Props) => {
  return (
    <button className={clsx(styles.root, className)} type="button" {...props} />
  );
};
