/* eslint-disable react/jsx-no-literals */
import React, { FunctionComponent } from 'react';
import { dateFormatter } from '../../../util/formatter';
import { ApiBill, ApiBillHistory } from '../../../util/types';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '../../atoms';
import { MedicalSpendHistoryRow } from '../MedicalSpendHistoryRow';
import scss from './MedicalSpendHistoryBills.module.scss';

const classes = scss as {
  root: string;
  title: string;
  table: string;
};

export type MedicalSpendHistoryBillsProps = {
  /**
   * Don't allow children elements
   */
  children?: never;
  /**
   * Class name for the root element
   */
  className?: string;
  /**
   * Medical spend history total numbers
   */
  data: ApiBillHistory;
  /**
   * Callback to call when user presses `Download Explanation of Sharing` button
   */
  onDownloadEOS: (data: ApiBill) => void;
};

export const MedicalSpendHistoryBills: FunctionComponent<MedicalSpendHistoryBillsProps> = ({
  data,
  onDownloadEOS,
}) => {
  const claims = data.plans.reduce((claims: ApiBill[], plan) => {
    if (plan.claims) {
      return [...claims, ...plan.claims];
    }
    return claims;
  }, []);
  return (
    <div className={classes.root}>
      <div className={classes.title}>
        <Typography className={classes.title} gutterBottom variant="h3">
          Medical Billing History
        </Typography>
        <Typography variant="body2">
          {`For membership year ${dateFormatter.format(
            Date.parse(data.startDate),
          )} to ${dateFormatter.format(Date.parse(data.endDate))}`}
        </Typography>
      </div>
      <TableContainer>
        <Table className={classes.table}>
          <TableHead>
            <TableRow size="large">
              <TableCell />
              <TableCell>
                <div>Service</div>
                <div>Date</div>
              </TableCell>
              <TableCell>
                <div>Bill Received</div>
                <div>Date</div>
              </TableCell>
              <TableCell>
                <div>Processed</div>
                <div>Date</div>
              </TableCell>
              <TableCell>
                <div>Patient</div>
                <div>Name</div>
              </TableCell>
              <TableCell>Provider</TableCell>
              <TableCell>
                <div>My</div>
                <div>Responsibility</div>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {claims.map((claim) => {
              return (
                <MedicalSpendHistoryRow
                  key={claim.claimId}
                  data={claim}
                  onDownloadEOS={onDownloadEOS}
                />
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
