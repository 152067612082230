import MuiTableBody, {
  TableBodyProps as MuiTableBodyProps,
} from '@material-ui/core/TableBody';
import React, { FunctionComponent } from 'react';
import { ClassesProp } from '../../util/types';
import scss from './TableBody.module.scss';

const classes = scss as ClassesProp<MuiTableBodyProps>;

export type TableBodyProps = MuiTableBodyProps;

export const TableBody: FunctionComponent<TableBodyProps> = (props) => {
  return <MuiTableBody classes={{ root: classes.root }} {...props} />;
};
