import clsx from 'clsx';
import React, { MouseEventHandler } from 'react';
import scss from './Button.module.scss';

const styles = scss as {
  root: string;
  dark: string;
  light: string;
  selected: string;
};

export interface ButtonProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  /**
   * Use light background for the button
   */
  isLight?: boolean;
  /**
   * Is button selected
   */
  isSelected?: boolean;
  /**
   * Click event handler
   */
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

/**
 * Primary button
 */
export const Button = ({
  className,
  isLight = false,
  isSelected = false,
  ...props
}: ButtonProps) => {
  return (
    <button
      className={clsx(
        styles.root,
        isLight ? styles.light : styles.dark,
        isSelected && styles.selected,
        className,
      )}
      type="button"
      {...props}
    />
  );
};
