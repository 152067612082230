/* eslint-disable jsx-a11y/heading-has-content */
import React, { ReactNode } from 'react';
import clsx from 'clsx';
import scss from './Heading.module.scss';

const styles = scss as {
  h2: string;
  h3: string;
  h4: string;
};

interface Props
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLHeadingElement>,
    HTMLHeadingElement
  > {
  level: 'h2' | 'h3' | 'h4';
  children: ReactNode;
}

export const Heading = ({ className, level, ...props }: Props) => {
  switch (level) {
    case 'h2':
      return <h2 className={clsx(styles.h2, className)} {...props} />;
    case 'h3':
      return <h3 className={clsx(styles.h3, className)} {...props} />;
    case 'h4':
      return <h4 className={clsx(styles.h4, className)} {...props} />;
    default:
      return null;
  }
};
