import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ProgramOptionSlug } from '../../util/helper';
import scss from './DpcOptionModalContent.module.scss';

const styles = scss as {
  root: string;
  emphasis: string;
  smallNote: string;
  radioButtons: string;
  radioButton: string;
};

export type ChangeProgramHandler = (slug: ProgramOptionSlug) => void;

interface Props {
  className?: string;
  selectedProgram: ProgramOptionSlug | null;
  onChangeProgram: ChangeProgramHandler;
}

export const DpcOptionModalContent = ({
  className,
  selectedProgram,
  onChangeProgram,
}: Props) => {
  const { t } = useTranslation('footnotes');
  return (
    <div className={clsx(styles.root, className)}>
      <p>
        <em>{t('modals.dpcProgramNotice.part1')}</em>
      </p>
      <p>
        {t('modals.dpcProgramNotice.part2')}
        <em>{t('modals.dpcProgramNotice.part3')}</em>
        {t('modals.dpcProgramNotice.part4')}
      </p>
      <p>
        {t('modals.dpcProgramNotice.part5')}
        <em>{t('modals.dpcProgramNotice.part6')}</em>
        {t('modals.dpcProgramNotice.part7')}
      </p>
      <div className={styles.radioButtons}>
        <div className={styles.radioButton}>
          <input
            checked={selectedProgram === 'APLL'}
            id="apll"
            name="programOption"
            onChange={() => onChangeProgram('APLL')}
            type="radio"
            value="APLL"
          />
          <label htmlFor="apll">{t('apll', { ns: 'common' })}</label>
        </div>
        <div className={styles.radioButton}>
          <input
            checked={selectedProgram === 'DPC'}
            id="dpc"
            name="programOption"
            onChange={() => onChangeProgram('DPC')}
            type="radio"
            value="DPC"
          />
          <label htmlFor="dpc">{t('dpc', { ns: 'common' })}</label>
        </div>
      </div>
      <p className={styles.smallNote}>
        <em>{t('modals.dpcProgramNotice.part8')}</em>
        {t('modals.dpcProgramNotice.part9')}
      </p>
    </div>
  );
};
