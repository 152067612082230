/* eslint-disable react/jsx-max-depth */
/* eslint-disable react/jsx-no-literals */
import { Grid } from '@material-ui/core';
import { GetAppRounded } from '@material-ui/icons';
import clsx from 'clsx';
import React, { Fragment, FunctionComponent, MouseEventHandler } from 'react';
import { currencyFormatter } from '../../../util/formatter';
import { ApiBill } from '../../../util/types';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from '../../atoms';
import scss from './MedicalSpendHistoryDetails.module.scss';
import scriptureData from './scriptures.json';

const classes = scss as {
  root: string;
  title: string;
  billId: string;
  head: string;
  row: string;
  footing: string;
  summary: string;
  check: string;
  calculation: string;
  reasonCodes: string;
  reasonCode: string;
  reasonDescription: string;
  total: string;
  pending: string;
  pendingVerse: string;
  pendingVerseRef: string;
};

export type MedicalSpendHistoryDetailsProps = {
  /**
   * Medical spend history total numbers
   */
  data: ApiBill;
  /**
   * Class applied to both the main row and the collapsed content row
   */
  className?: string;
  /**
   * Callback to call when user presses `Download Explanation of Sharing` button
   */
  onDownloadEOS: (data: ApiBill) => void;
};

export const MedicalSpendHistoryDetails: FunctionComponent<MedicalSpendHistoryDetailsProps> = ({
  data,
  className,
  onDownloadEOS,
}) => {
  const reasonCodes: Record<string, string> = {};
  data.serviceLines?.forEach((serviceLine) => {
    serviceLine.reasonCodes?.forEach((reasonCode) => {
      reasonCodes[reasonCode.code] = reasonCode.description;
    });
  });

  const onClickDownloadEOS: MouseEventHandler<HTMLButtonElement> = () => {
    onDownloadEOS(data);
  };

  const scripture =
    scriptureData[Math.floor(Math.random() * scriptureData.length)];

  return (
    <div className={clsx(classes.root, className)}>
      <div className={classes.title}>
        <Table>
          <TableBody className={classes.head}>
            <TableRow className={classes.row} hideDivider size="medium">
              <TableCell dark>
                <Typography component="h3" dark variant="h4">
                  {'Bill Number: '}
                  <span className={classes.billId}>{data.claimId}</span>
                </Typography>
              </TableCell>
              {data.processed && (
                <TableCell align="right">
                  <Button
                    onClick={onClickDownloadEOS}
                    startIcon={<GetAppRounded>Download</GetAppRounded>}
                    variant="outlined"
                  >
                    Download Explanation of Sharing
                  </Button>
                </TableCell>
              )}
            </TableRow>
          </TableBody>
        </Table>
      </div>
      {data.processed && (
        <Fragment>
          <Table>
            <TableHead className={classes.head}>
              <TableRow className={classes.row} size="medium">
                <TableCell dark>Service</TableCell>
                <TableCell dark>
                  <div>Reason</div>
                  <div>Codes</div>
                </TableCell>
                <TableCell dark>Billed</TableCell>
                <TableCell dark>Adjustment</TableCell>
                <TableCell dark>Ineligible</TableCell>
                <TableCell dark>
                  <div>Medi-Share</div>
                  <div>Responsibility</div>
                </TableCell>
                <TableCell dark>
                  <div>My</div>
                  <div>Responsibility</div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.serviceLines?.map((serviceLine) => (
                <TableRow
                  key={`${serviceLine.claimDetailId}${serviceLine.benefitCode}`}
                  className={classes.row}
                  size="medium"
                >
                  <TableCell contentSize="small">
                    {serviceLine.benefitCodeDescription}
                  </TableCell>
                  <TableCell contentSize="small">
                    {serviceLine.reasonCodes?.map((reasonCode) => (
                      <div key={reasonCode.code}>{reasonCode.code}</div>
                    ))}
                  </TableCell>
                  <TableCell contentSize="small">
                    {currencyFormatter.format(serviceLine.total)}
                  </TableCell>
                  <TableCell contentSize="small">
                    {currencyFormatter.format(serviceLine.discounted!)}
                  </TableCell>
                  <TableCell contentSize="small">
                    {currencyFormatter.format(serviceLine.ineligible!)}
                  </TableCell>
                  <TableCell contentSize="small">
                    {currencyFormatter.format(
                      serviceLine.mediShareResponsibility!,
                    )}
                  </TableCell>
                  <TableCell contentSize="small">
                    {currencyFormatter.format(
                      serviceLine.overallMemberResponsibility!,
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow className={classes.row} size="large">
                <TableCell colSpan={2} contentSize="small">
                  Total
                </TableCell>
                <TableCell contentSize="small">
                  {currencyFormatter.format(data.total)}
                </TableCell>
                <TableCell contentSize="small">
                  {currencyFormatter.format(data.discounted)}
                </TableCell>
                <TableCell contentSize="small">
                  {currencyFormatter.format(data.ineligible)}
                </TableCell>
                <TableCell contentSize="small">
                  {currencyFormatter.format(data.medishareResponsibility)}
                </TableCell>
                <TableCell contentSize="small">
                  {currencyFormatter.format(data.overallMemberResponsibility)}
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
          {data.checkAmount && data.checkNumber && data.checkPayee && (
            <Typography className={classes.check} variant="body2">
              {`A check (# ${
                data.checkNumber
              }) in the amount of ${currencyFormatter.format(
                data.checkAmount,
              )} was paid to ${data.checkPayee}.`}
            </Typography>
          )}
          <div className={classes.summary}>
            <Grid container spacing={6}>
              <Grid item xs={6}>
                <div className={classes.calculation}>
                  <Table>
                    <TableBody>
                      <TableRow hideDivider size="medium">
                        <TableCell colSpan={2}>
                          <Typography dark variant="h4">
                            How we calculated what you pay
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow hideDivider size="medium">
                        <TableCell contentSize="large" dark>
                          Annual Household Portion
                        </TableCell>
                        <TableCell align="right" contentSize="large" dark>
                          {currencyFormatter.format(data.ahpDeducted)}
                        </TableCell>
                      </TableRow>
                      <TableRow hideDivider size="medium">
                        <TableCell contentSize="large" dark>
                          Provider Fee
                        </TableCell>
                        <TableCell align="right" contentSize="large" dark>
                          {currencyFormatter.format(data.copayAmount)}
                        </TableCell>
                      </TableRow>
                      {/* Display coshare responsibility on coshare accounts only */}
                      {data.coInsAmt !== null && (
                        <TableRow hideDivider size="medium">
                          <TableCell contentSize="large" dark>
                            Co-Share Responsibility
                          </TableCell>
                          <TableCell align="right" contentSize="large" dark>
                            {currencyFormatter.format(data.coInsAmt)}
                          </TableCell>
                        </TableRow>
                      )}
                      <TableRow hideDivider size="medium">
                        <TableCell contentSize="large" dark>
                          Ineligible
                        </TableCell>
                        <TableCell align="right" contentSize="large" dark>
                          {currencyFormatter.format(data.ineligible)}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                    <TableFooter>
                      <TableRow className={classes.total} size="medium">
                        <TableCell contentSize="large" dark>
                          Total
                        </TableCell>
                        <TableCell align="right" contentSize="large" dark>
                          {currencyFormatter.format(
                            data.overallMemberResponsibility,
                          )}
                        </TableCell>
                      </TableRow>
                    </TableFooter>
                  </Table>
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className={classes.reasonCodes}>
                  <Table>
                    <TableBody>
                      <TableRow hideDivider size="medium">
                        <TableCell colSpan={2}>
                          <Typography variant="h4">Reason Codes</Typography>
                        </TableCell>
                      </TableRow>
                      {Object.entries(reasonCodes).map(([key, value]) => (
                        <TableRow
                          key={key}
                          className={classes.total}
                          hideDivider
                        >
                          <TableCell
                            bold
                            className={classes.reasonCode}
                            contentSize="small"
                            size="small"
                          >
                            {key}
                          </TableCell>
                          <TableCell
                            className={classes.reasonDescription}
                            contentSize="small"
                            size="small"
                          >
                            {value}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
              </Grid>
            </Grid>
          </div>
        </Fragment>
      )}
      {!data.processed && (
        <div>
          <Typography className={classes.pending} variant="body2">
            {`We have received this bill and are currently processing it. \
            Please, check back later.`}
          </Typography>
          <Typography className={classes.pendingVerse} variant="body2">
            {scripture.verse}
          </Typography>
          <Typography className={classes.pendingVerseRef} variant="body2">
            {scripture.verseRef}
          </Typography>
        </div>
      )}
    </div>
  );
};
