import clsx from 'clsx';
import React from 'react';
import scss from './BulletList.module.scss';

const styles = scss as {
  root: string;
  heading: string;
};

interface Props
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  className?: string;
}

export const BulletList = ({ className, ...props }: Props) => {
  return <div className={clsx(styles.root, className)} {...props} />;
};
