import clsx from 'clsx';
import React from 'react';
import scss from './Small.module.scss';

const styles = scss as {
  root: string;
};

type Props = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLElement>,
  HTMLElement
>;

export const Small = ({ className, children, ...props }: Props) => {
  return (
    <small className={clsx(styles.root, className)} {...props}>
      {children}
    </small>
  );
};
