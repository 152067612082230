import clsx from 'clsx';
import React, { useState } from 'react';
import scss from './TabNav.module.scss';

const styles = scss as {
  root: string;
  tabButton: string;
  tabButtonActive: string;
};

export type TabNavHandler = (tabName: string, index: number) => void;

interface Props {
  tabNames: string[];
  selectedTab?: number;
  className?: string;
  children?: never;
  onChangeTab: TabNavHandler;
}

export const TabNav = ({
  className,
  tabNames,
  selectedTab = 0,
  onChangeTab,
}: Props) => {
  const [currentTab, setCurrentTab] = useState(selectedTab);

  const handleClick = (index: number): void => {
    setCurrentTab(index);
    onChangeTab(tabNames[index], index);
  };

  return (
    <div className={clsx(styles.root, className)}>
      {tabNames.map((name, index) => (
        <button
          key={name}
          className={clsx(
            styles.tabButton,
            index === currentTab && styles.tabButtonActive,
          )}
          onClick={() => {
            handleClick(index);
          }}
          type="button"
        >
          {tabNames[index]}
        </button>
      ))}
    </div>
  );
};
