import MuiTableRow, {
  TableRowProps as MuiTableRowProps,
} from '@material-ui/core/TableRow';
import clsx from 'clsx';
import React, { FunctionComponent } from 'react';
import { UnreachableCaseError } from 'ts-essentials';
import { Classes, NoClasses } from '../../../util/storybook';
import scss from './TableRow.module.scss';

type Size = 'medium' | 'large';

const classes = scss as Classes<MuiTableRowProps> & {
  sizeMedium: string;
  sizeLarge: string;
  noDivider: string;
};

export type TableRowProps = NoClasses<MuiTableRowProps> & {
  size?: Size;
  hideDivider?: boolean;
};

const getSizeClass = (size?: Size): string | undefined => {
  if (!size) {
    return undefined;
  }
  switch (size) {
    case 'medium':
      return classes.sizeMedium;
    case 'large':
      return classes.sizeLarge;
    default:
      throw new UnreachableCaseError(size);
  }
};

export const TableRow: FunctionComponent<TableRowProps> = ({
  size,
  hideDivider = false,
  className,
  ...props
}) => {
  return (
    <MuiTableRow
      classes={{
        root: classes.root,
        selected: classes.selected,
        hover: classes.hover,
      }}
      className={clsx(
        getSizeClass(size),
        hideDivider && classes.noDivider,
        className,
      )}
      {...props}
    />
  );
};
