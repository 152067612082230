import { ClaimsDashboard } from '@ccm-innovation/pricing-components';
import { Box } from '@material-ui/core';
import clsx from 'clsx';
import React, { FunctionComponent } from 'react';
import { ApiBill, ApiBillHistory } from '../../../util/types';
import { MedicalSpendHistoryBills } from '../MedicalSpendHistoryBills';
import { MedicalSpendHistoryHeading } from '../MedicalSpendHistoryHeading';
import {
  MedicalSpendHistoryTotals,
  MedicalSpendHistoryTotalsData,
} from '../MedicalSpendHistoryTotals';
import scss from './MedicalSpendHistory.module.scss';

const classes = scss as {
  root: string;
  bills: string;
  totals: string;
};

export type MedicalSpendHistoryProps = {
  /**
   * Don't allow children elements
   */
  children?: never;
  /**
   * Medical spend history total numbers
   */
  billsData: ApiBillHistory;
  /**
   * Medical spend history total numbers
   */
  totalsData: MedicalSpendHistoryTotalsData;
  /**
   * Class name for the root element
   */
  className?: string;
  /**
   * Callback to call when user presses `Download Explanation of Sharing` button
   */
  onDownloadEOS: (data: ApiBill) => void;
};

export const MedicalSpendHistory: FunctionComponent<MedicalSpendHistoryProps> = ({
  billsData,
  totalsData,
  className,
  onDownloadEOS,
}) => {
  return (
    <div className={clsx(classes.root, className)}>
      <Box py={2}>
        <MedicalSpendHistoryHeading />
      </Box>
      <Box py={2}>
        <ClaimsDashboard />
      </Box>
      <Box py={2}>
        <MedicalSpendHistoryBills
          className={classes.bills}
          data={billsData}
          onDownloadEOS={onDownloadEOS}
        />
      </Box>
      <Box py={2}>
        <MedicalSpendHistoryTotals
          className={classes.totals}
          data={totalsData}
        />
      </Box>
    </div>
  );
};
