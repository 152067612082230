import clsx from 'clsx';
import dayjs from 'dayjs';
import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  createMultiLineSpan,
  getDollarAmount,
  hasApll,
  hasDpc,
  isMedishare,
  ProgramLevelPayload,
  ProgramOptionSlug,
} from '../../util/helper';
import { Button } from '../Button';
import { CoshareModalContent } from '../CoshareModalContent';
import { DpcOptionModalContent } from '../DpcOptionModalContent';
import { Heading } from '../Heading';
import { LinkButton } from '../LinkButton';
import { Modal } from '../Modal';
import scss from './ProgramLevel.module.scss';

const styles = scss as {
  root: string;
  rootCurrent: string;
  rootSelected: string;
  summaryBox: string;
  priceBox: string;
  priceHeading: string;
  priceAmount: string;
  programHeadingBox: string;
  priceDetail: string;
  priceDetailValue: string;
  heading: string;
  detailsBox: string;
  detailsItems: string;
  detailsItem: string;
  detailsItemsOrDivider: string;
  detailsAhp: string;
  selectButton: string;
  selectedBlock: string;
  currentLevel: string;
};

export interface ProgramSelection {
  level: ProgramLevelPayload;
  option: ProgramOptionSlug;
}

export type ClickViewDetailsHandler = (data: ProgramLevelPayload) => void;
export type ClickProgramOptionHandler = (optionSlug: ProgramOptionSlug) => void;
export type ClickSelectProgramLevelHandler = (data: ProgramSelection) => void;

export interface ProgramLevelProps {
  children?: never;
  /**
   * Class that overrides and augments CSS properties
   */
  className?: string;
  /**
   * Payload for a single program level
   */
  data: ProgramLevelPayload;
  /**
   * Display the rate with the heathy discount
   */
  showHealthDiscount: boolean;
  /**
   * Is the program level selected for application
   */
  isSelected: boolean;
  /**
   * Handler for when the user clicks "View Details"
   */
  onClickViewDetails: ClickViewDetailsHandler;
  /**
   * Handler for when the user clicks APLL or DPC link
   */
  onClickProgramOption: ClickProgramOptionHandler;
  /**
   * Handler for when the user clicks "Select" button
   */
  onSelectProgramLevel: ClickSelectProgramLevelHandler;
  /**
   * The ID of the root element, used to display modals, e.g. #root
   */
  rootElementSelector: string;
}

enum ModalType {
  DpcOption,
  Coshare,
}

/**
 * Container that displays details and pricing of a single program level
 */
export const ProgramLevel = ({
  className,
  data,
  isSelected,
  showHealthDiscount,
  onClickViewDetails,
  onClickProgramOption,
  onSelectProgramLevel,
  rootElementSelector,
}: ProgramLevelProps) => {
  const { t, i18n } = useTranslation();

  const [modalType, setModalType] = useState(null as ModalType | null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [programOption, setProgramOption] = useState(
    null as ProgramOptionSlug | null,
  );
  const [acknowledged, setAcknowledged] = useState(false);

  const getProgramName = () => {
    if (isMedishare(data)) {
      return t('medishareProgramName', { amount: data.AhpAmount });
    }
    return t('coshareProgramName', { amount: data.AhpAmount });
  };

  const handleSelectProgramLevel = () => {
    if (hasDpc(data)) {
      setModalType(ModalType.DpcOption);
      setIsModalOpen(true);
      setModalTitle(getProgramName());
      setProgramOption(null);
    } else if (data.Name.startsWith('CS')) {
      setModalType(ModalType.Coshare);
      setIsModalOpen(true);
      setModalTitle(t('coshareAcknowledgement'));
      setProgramOption('APLL');
      setAcknowledged(false);
    } else {
      onSelectProgramLevel({ level: data, option: 'APLL' });
    }
  };

  const handleSelectProgramLevelModal = () => {
    if (programOption) {
      onSelectProgramLevel({
        level: data,
        option: programOption,
      });
      setIsModalOpen(false);
    }
  };

  return (
    <div
      className={clsx(
        styles.root,
        data.Current && styles.rootCurrent,
        isSelected && styles.rootSelected,
        className,
      )}
    >
      <div className={styles.summaryBox}>
        <div className={styles.programHeadingBox}>
          <Heading className={styles.heading} level="h2">
            {getProgramName()}
          </Heading>
          <LinkButton onClick={() => onClickViewDetails(data)}>
            {t('viewDetails')}
          </LinkButton>
        </div>
        <div className={styles.detailsBox}>
          <div>
            <Heading className={styles.heading} level="h4">
              {t('annualHouseholdPortion')}
            </Heading>
            <div className={styles.detailsItems}>
              <div className={styles.detailsAhp}>
                {getDollarAmount(data.AhpAmount, false, i18n.language)}
              </div>
            </div>
          </div>
          <div>
            <Heading className={styles.heading} level="h4">
              {t('programOptions')}
            </Heading>
            <div className={styles.detailsItems}>
              {hasApll(data) && (
                <div className={styles.detailsItem}>
                  <LinkButton onClick={() => onClickProgramOption('APLL')}>
                    {createMultiLineSpan(t('apll'))}
                  </LinkButton>
                </div>
              )}
              {hasDpc(data) && (
                <Fragment>
                  <div
                    className={clsx(
                      styles.detailsItemsOrDivider,
                      styles.detailsItem,
                    )}
                  >
                    {t('orDivider')}
                  </div>
                  <div className={styles.detailsItem}>
                    <LinkButton onClick={() => onClickProgramOption('DPC')}>
                      {t('dpc')}
                    </LinkButton>
                  </div>
                </Fragment>
              )}
              {data.Options.length === 0 && (
                <div className={styles.detailsItem}>{t('na')}</div>
              )}
            </div>
          </div>
          <div>
            <Heading className={styles.heading} level="h4">
              {t('providerFees')}
            </Heading>
            <div className={styles.detailsItems}>
              <div className={styles.detailsItem}>
                {t('options.telemedicine')}
              </div>
              <div className={styles.detailsItem}>{t('options.office')}</div>
              <div className={styles.detailsItem}>{t('options.emergency')}</div>
            </div>
          </div>
        </div>
        {data.McrAmount && (
          <div>
            <Heading className={styles.heading} level="h4">
              {t('coshareResponsibility')}
            </Heading>
            <ul className={styles.detailsItems}>
              <li>
                {t('coshareDetails', {
                  coshareAmount: getDollarAmount(
                    data.McrAmount - data.AhpAmount,
                    false,
                    i18n.language,
                  ),
                })}
              </li>
            </ul>
          </div>
        )}
      </div>
      <div className={styles.priceBox}>
        {data.Current ? (
          <div className={styles.currentLevel}>
            <span>
              {createMultiLineSpan(t('currentProgram'))}
              <br />
              {getDollarAmount(data.TotalAmount, true, i18n.language)}
            </span>
          </div>
        ) : (
          <Fragment>
            <div className={styles.priceHeading}>{t('monthlyShareAmount')}</div>
            <div className={styles.priceAmount}>
              {getDollarAmount(data.TotalAmount, true, i18n.language)}
            </div>
            {showHealthDiscount && (
              <div className={styles.priceDetail}>
                {t('healthyDiscount')}
                <span className={styles.priceDetailValue}>
                  {data.HealthyDiscountTotalAmount
                    ? getDollarAmount(
                        data.HealthyDiscountTotalAmount,
                        false,
                        i18n.language,
                      )
                    : t('na')}
                </span>
              </div>
            )}
            <div className={styles.priceDetail}>
              {t('switchCost')}
              <span className={styles.priceDetailValue}>
                {getDollarAmount(data.SwitchFeeAmount, false, i18n.language)}
              </span>
            </div>
            {isSelected ? (
              <div className={styles.selectedBlock}>{t('selected')}</div>
            ) : (
              <Button
                className={styles.selectButton}
                onClick={handleSelectProgramLevel}
              >
                {t('select')}
              </Button>
            )}
            <div className={styles.priceDetail}>
              {t('effectiveDate')}
              <span className={styles.priceDetailValue}>
                {data.SwitchDate
                  ? dayjs(data.SwitchDate).format('MM/DD/YYYY')
                  : t('na')}
              </span>
            </div>
          </Fragment>
        )}
      </div>
      <Modal
        contentLabel={modalTitle}
        isOpen={isModalOpen}
        isSelectDisabled={
          (modalType === ModalType.DpcOption && !programOption) ||
          (modalType === ModalType.Coshare && !acknowledged)
        }
        onClickSelect={handleSelectProgramLevelModal}
        onRequestClose={() => setIsModalOpen(false)}
        rootElementSelector={rootElementSelector}
      >
        {modalType === ModalType.DpcOption && (
          <DpcOptionModalContent
            onChangeProgram={(slug) => setProgramOption(slug)}
            selectedProgram={programOption}
          />
        )}
        {modalType === ModalType.Coshare && (
          <CoshareModalContent
            acknowledged={acknowledged}
            onAcknowledge={(value) => setAcknowledged(value)}
          />
        )}
      </Modal>
    </div>
  );
};
