import clsx from 'clsx';
import React, { MouseEventHandler, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  programLevelComparator,
  ProgramLevelPayload,
  SwitchOptionsPayload,
} from '../../util/helper';
import { AhpFootnotes } from '../AhpFootnotes';
import { ApllModalContent } from '../ApllModalContent';
import { Button } from '../Button';
import { DpcModalContent } from '../DpcModalContent';
import { Heading } from '../Heading';
import { Modal } from '../Modal';
import { ProgramComparison } from '../ProgramComparison';
import {
  ClickProgramOptionHandler,
  ClickSelectProgramLevelHandler,
  ClickViewDetailsHandler,
  ProgramLevel,
  ProgramSelection,
} from '../ProgramLevel';
import { ProgramModalContent } from '../ProgramModalContent';
import { Small } from '../Small';
import { TabNav, TabNavHandler } from '../TabNav';
import scss from './SwitchToolContent.module.scss';

const styles = scss as {
  root: string;
  heading: string;
  buttonsGroup: string;
};

export type ConfirmSwitchRequestHandler = (data: ProgramSelection) => void;
export type CancelSwitchRequestHandler = () => void;

enum ModalContentType {
  Apll,
  Dpc,
  ProgramDetails,
}

interface Props {
  className?: string;
  data: SwitchOptionsPayload;
  onCancelSwitchRequest: CancelSwitchRequestHandler;
  onConfirmSwitchRequest: ConfirmSwitchRequestHandler;
  rootElementSelector: string;
  showHealthDiscount: boolean;
}

export const SwitchToolContent = ({
  className,
  data,
  onCancelSwitchRequest,
  onConfirmSwitchRequest,
  rootElementSelector,
  showHealthDiscount,
}: Props) => {
  const { t } = useTranslation('common');
  const [currentTab, setCurrentTab] = useState(0);
  const [selectedProgramLevel, setSelectedProgramLevel] = useState(
    null as ProgramSelection | null,
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalContentType, setModalContentType] = useState(
    null as ModalContentType | null,
  );
  const [programModalData, setProgramModalData] = useState(
    null as ProgramLevelPayload | null,
  );

  const handleChangeTab: TabNavHandler = (name, index) => {
    setCurrentTab(index);
  };

  const getProgramName = (data: ProgramLevelPayload): string => {
    return data.Name.startsWith('MS')
      ? t('medishareProgramName', { amount: data.AhpAmount })
      : t('coshareProgramName', { amount: data.AhpAmount });
  };

  const handleClickViewDetails: ClickViewDetailsHandler = (data) => {
    setProgramModalData(data);
    setModalContentType(ModalContentType.ProgramDetails);
    setModalTitle(
      t('programDetails', {
        programName: getProgramName(data),
      }),
    );
    setIsModalOpen(true);
  };

  const handleClickProgramOption: ClickProgramOptionHandler = (slug) => {
    switch (slug) {
      case 'APLL':
        setModalContentType(ModalContentType.Apll);
        setModalTitle(t('apll'));
        break;
      case 'DPC':
        setModalContentType(ModalContentType.Dpc);
        setModalTitle(t('dpcDPC'));
        break;
    }
    setIsModalOpen(true);
  };

  const handleClickSelectProgramLevel: ClickSelectProgramLevelHandler = (
    programLevel,
  ) => {
    setSelectedProgramLevel(programLevel);
  };

  const handleClickConfirm: MouseEventHandler<HTMLButtonElement> = () => {
    if (selectedProgramLevel) {
      onConfirmSwitchRequest(selectedProgramLevel);
    }
  };

  const handleClickCancel: MouseEventHandler<HTMLButtonElement> = () => {
    onCancelSwitchRequest();
  };

  const medishare2Levels =
    data.Programs.filter((program) => program.Name === 'M2')[0]
      ?.ProgramLevels || ([] as ProgramLevelPayload[]);

  const medishare3Levels =
    data.Programs.filter((program) => program.Name === 'MS3')[0]
      ?.ProgramLevels || ([] as ProgramLevelPayload[]);

  const medishareLevels = [...medishare2Levels, ...medishare3Levels].sort(
    programLevelComparator,
  );

  const coshareLevels =
    data.Programs.filter(
      (program) => program.Name === 'CS1',
    )[0]?.ProgramLevels.sort(programLevelComparator) || [];

  const tabs = coshareLevels.length
    ? [t('medishare'), t('medishareWithCoshare'), t('programComparison')]
    : [t('medishare'), t('programComparison')];

  return (
    <div className={clsx(styles.root, className)}>
      <Heading level="h2">{t('switchYourAHP')}</Heading>
      <Small>{t('switchDisclaimer', { ns: 'footnotes' })}</Small>
      <TabNav
        onChangeTab={handleChangeTab}
        selectedTab={currentTab}
        tabNames={tabs}
      />
      {tabs[currentTab] === t('medishare') &&
        medishareLevels.map((programLevel) => (
          <ProgramLevel
            key={programLevel.Name}
            data={programLevel}
            isSelected={selectedProgramLevel?.level.Name === programLevel.Name}
            onClickProgramOption={handleClickProgramOption}
            onClickViewDetails={handleClickViewDetails}
            onSelectProgramLevel={handleClickSelectProgramLevel}
            rootElementSelector={rootElementSelector}
            showHealthDiscount={showHealthDiscount}
          />
        ))}
      {tabs[currentTab] === t('medishareWithCoshare') &&
        coshareLevels.map((programLevel) => (
          <ProgramLevel
            key={programLevel.Name}
            data={programLevel}
            isSelected={selectedProgramLevel?.level.Name === programLevel.Name}
            onClickProgramOption={handleClickProgramOption}
            onClickViewDetails={handleClickViewDetails}
            onSelectProgramLevel={handleClickSelectProgramLevel}
            rootElementSelector={rootElementSelector}
            showHealthDiscount={showHealthDiscount}
          />
        ))}
      {tabs[currentTab] === t('programComparison') && (
        <ProgramComparison data={data} />
      )}
      <AhpFootnotes />
      <div className={styles.buttonsGroup}>
        <Button isLight onClick={handleClickCancel}>
          {t('back')}
        </Button>
        <Button disabled={!selectedProgramLevel} onClick={handleClickConfirm}>
          {t('next')}
        </Button>
      </div>
      <Modal
        contentLabel={modalTitle}
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        rootElementSelector={rootElementSelector}
      >
        {modalContentType === ModalContentType.Apll && <ApllModalContent />}
        {modalContentType === ModalContentType.Dpc && <DpcModalContent />}
        {modalContentType === ModalContentType.ProgramDetails &&
          programModalData && <ProgramModalContent data={programModalData} />}
      </Modal>
    </div>
  );
};
