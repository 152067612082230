import MuiTable, { TableProps as MuiTableProps } from '@material-ui/core/Table';
import React, { FunctionComponent } from 'react';
import { Classes } from '../../../util/storybook';
import scss from './Table.module.scss';

const classes = scss as Classes<MuiTableProps>;

export type TableProps = MuiTableProps;

export const Table: FunctionComponent<TableProps> = (props) => {
  return <MuiTable classes={{ root: classes.root }} {...props} />;
};
