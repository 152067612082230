import i18next, { i18n, InitOptions } from 'i18next';
import { initReactI18next } from 'react-i18next';
import enUS from './en-US';
import esUS from './es-US';

export enum Languages {
  'en-US' = 'en-US',
  'es-US' = 'es-US',
}
export type Language = keyof typeof Languages;

export enum Namespaces {
  Common = 'common',
  Footnotes = 'footnotes',
}

const createI18nInstance = async (options: InitOptions): Promise<i18n> => {
  let i18nInstance: i18n | undefined;

  await new Promise((resolve, reject) => {
    i18nInstance = i18next
      .use(initReactI18next)
      .createInstance(options, (err, t) => {
        if (err) {
          reject(err);
        }
        resolve();
      });
  });

  return i18nInstance as i18n;
};

export const createI18n = async (
  language: Language = 'en-US',
): Promise<i18n> => {
  return createI18nInstance({
    resources: {
      'en-US': enUS,
      'es-US': esUS,
    },
    defaultNS: Namespaces.Common,
    lng: language,
    debug: false,
    fallbackLng: false,
    appendNamespaceToMissingKey: true,
    parseMissingKeyHandler: (value) => {
      return `{{${value}}}`;
    },
  });
};
