import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Anchor } from '../Anchor';
import scss from './ApllModalContent.module.scss';

const styles = scss as {
  root: string;
  emphasis: string;
};

interface Props {
  className?: string;
}

export const ApllModalContent = ({ className }: Props) => {
  const { t } = useTranslation('footnotes');
  return (
    <div className={clsx(styles.root, className)}>
      <p>{t('modals.apll.part1')}</p>
      <p>
        {t('modals.apll.part2')}
        <Anchor
          className={styles.emphasis}
          href="https://mychristiancare.org/Annual-Physicals/"
          target="_blank"
        >
          {t('modals.apll.part3')}
        </Anchor>
        {t('modals.apll.part4')}
      </p>
    </div>
  );
};
