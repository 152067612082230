import MuiTableFooter, {
  TableFooterProps as MuiTableFooterProps,
} from '@material-ui/core/TableFooter';
import React, { FunctionComponent } from 'react';
import { Classes } from '../../../util/storybook';
import scss from './TableFooter.module.scss';

const classes = scss as Classes<MuiTableFooterProps>;

export type TableFooterProps = MuiTableFooterProps;

export const TableFooter: FunctionComponent<TableFooterProps> = (props) => {
  return <MuiTableFooter classes={{ root: classes.root }} {...props} />;
};
