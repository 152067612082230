import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Anchor } from '../Anchor';
import scss from './DpcModalContent.module.scss';

const styles = scss as {
  root: string;
  emphasis: string;
};

interface Props {
  className?: string;
}

export const DpcModalContent = ({ className }: Props) => {
  const { t } = useTranslation('footnotes');
  return (
    <div className={clsx(styles.root, className)}>
      <p>
        {t('modals.dpc.part1')}
        <span className={styles.emphasis}>{t('modals.dpc.part2')}</span>
        {t('modals.dpc.part3')}
      </p>
      <p>
        <span className={styles.emphasis}> {t('modals.dpc.part4')}</span>
        {t('modals.dpc.part5')}
        <Anchor href="https://mapper.dpcfrontier.com/" target="_blank">
          {t('modals.dpc.part6')}
        </Anchor>
        {t('modals.dpc.part7')}
      </p>
      <p>
        {t('modals.dpc.part8')}
        <Anchor
          href="https://mychristiancare.org/Direct-Primary-Care"
          target="_blank"
        >
          {t('modals.dpc.part9')}
        </Anchor>
        {t('modals.dpc.part10')}
      </p>
    </div>
  );
};
