import React, { Fragment, ReactNode } from 'react';

export type ProgramName = 'M2' | 'MS3' | 'CS1';
export type ProgramLevelName =
  | 'MS3-3K'
  | 'MS3-6K'
  | 'MS3-9K'
  | 'MS3-12K'
  | 'CS1-3K'
  | 'CS1-6K'
  | 'AHP 1000';
export type ProgramOptionSlug = 'APLL' | 'DPC';

export interface ProductPayload {
  Name: string;
  Amount: number;
}

export interface OptionPayload {
  Slug: ProgramOptionSlug;
  Description: string;
}

export interface ProgramLevelPayload {
  Name: ProgramLevelName;
  Current: boolean;
  SwitchDate: string | null;
  SwitchFeeAmount: number;
  AhpAmount: number;
  McrAmount: number | null;
  TotalAmount: number;
  HealthyDiscountTotalAmount: number;
  Products: ProductPayload[];
  Options: OptionPayload[];
}

export interface ProgramPayload {
  Name: ProgramName;
  ProgramLevels: ProgramLevelPayload[];
}

export interface SwitchOptionsPayload {
  Message: 'Ok';
  Programs: ProgramPayload[];
}

export interface FootnotesSwitchDetails {
  Question: string;
  Answer: ReactNode;
}

export const createMultiLineSpan = (text: string): ReactNode => {
  const lines = text.split('\n');
  return (
    <span>
      {lines.map((line, index) => (
        <Fragment key={line}>
          {line}
          {index < lines.length - 1 && <br />}
        </Fragment>
      ))}
    </span>
  );
};

export const getDollarAmount = (
  amount: number,
  withCents: boolean,
  language: string,
): string => {
  return amount.toLocaleString(language, {
    style: 'currency',
    currency: 'USD',
    useGrouping: true,
    minimumFractionDigits: withCents ? 2 : 0,
    maximumFractionDigits: withCents ? 2 : 0,
  });
};

export const programLevelComparator = (
  a: ProgramLevelPayload,
  b: ProgramLevelPayload,
): number => {
  return a.TotalAmount - b.TotalAmount;
};

export const hasApll = (data: ProgramLevelPayload): boolean => {
  return !!data.Options.find((option) => option.Slug === 'APLL');
};

export const hasDpc = (data: ProgramLevelPayload): boolean => {
  return !!data.Options.find((option) => option.Slug === 'DPC');
};

export const isMedishare = (data: ProgramLevelPayload): boolean => {
  return data.Name.startsWith('MS') || data.Name.startsWith('AHP');
};
